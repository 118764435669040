body {
    margin: 0;
    font-weight: 100;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app {
    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-bottom: 0;

        &:not(.f-text-semibold) {
            .f-text-normal;
        }
    }

    a {
        color: #1ca8dd;

        &:focus,
        &:active {
            text-decoration: underline;
            outline: 2px solid #49B9E4;
        }
    }

    .ant-typography {
        &:not([class*='f-color-']) {
            .f-color-dark-black-s80;

            &.ant-typography-secondary {
                .f-color-dark-black-s50;
            }
        }

        pre {
            margin-top: 0;
            padding: 12px;
            border-radius: 6px;
            background: #f7f7f8;
            border: 1px solid #e9e9ea;
        }

        kbd {
            padding: 0 4px;
            .f-text-content;
            box-shadow: none;
            color: #f6772c;
            border-radius: 4px;
            background-color: #fef1ea;
            border: 1px solid #fabb95;
        }

        .ant-typography-copy {
            margin: 0;
            .size(24px);
            padding: 4px !important;
            border-radius: 4px !important;
            background-color: #e6f2f6 !important;
            .position(absolute, @top: 12px, @right: 12px);

            svg {
                .size(16px);
                fill: #0779a5;
            }
        }

        &.copyable-text {
            position: relative;

            pre {
                padding-top: 60px;

                &::before {
                    content: '';
                    .size(100%, 1px);
                    background-color: #e9e9ea;
                    .position(absolute, @top: 48px, @left: 0, @right: 0);
                }
            }
        }

        &.ant-typography-edit-content {
            left: 0;
        }

        .ant-typography-edit {
            &:focus,
            &:focus-visible {
                outline: 2px solid #49B9E4;
            }
        }
    }

    .ant-click-animating-node {
        display: none;
    }

    .wmde-markdown {
        .f-text-normal;
        word-break: break-word;
        .f-text-medium-content;
        font-family: @font-family;
        color: #4c4e54 !important;
    }

    // layout
    .ant-layout {
        background-color: #edf0f0;

        .ant-layout-sider {
            height: 100vh;
            position: fixed;
            width: 84px !important;
            min-width: 84px !important;
            max-width: 84px !important;
            background: #292c33;
            flex: 0 0 84px !important;

            & + .ant-layout {
                margin-left: 84px;
            }
        }
    }

    .ant-layout-content {
        width: 100%;
        padding: 24px;
        margin: 0 auto;
        overflow: auto;
        overflow-x: visible;
        background-color: #edf0f0;

        @media (max-width: @screen-lg) {
            padding: 24px;
        }
    }

    // Input
    .ant-input {
        height: 32px;
        border: none;
        .f-text-normal;
        border-radius: 0;
        padding-left: 10px;
        padding-right: 10px;
        .f-color-dark-black-s80;
        .f-text-medium-content;
        .f-text-single-line;
        background-color: #f4f6f8;

        &:hover {
            background-color: #f0f5f8;
        }

        &:focus {
            box-shadow: inset 0 0 0 1px #1ca8dd;
        }

        &::placeholder {
            .f-text-normal;
            color: #8f9094;
            font-family: @font-family;
        }
    }

    // Select
    .ant-select {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                border: none;
                border-radius: 0;
                min-height: 32px;
                padding-left: 10px;
                padding-right: 10px;
                .f-color-dark-black-s80;
                .f-text-medium-content;
                .f-text-single-line;
                background-color: #f4f6f8;

                .ant-select-selection-search {
                    margin-left: 0;
                }
            }

            &:hover .ant-select-selector {
                background-color: #f0f5f8;
            }

            &.ant-select-focused:not(.ant-select-disabled) {
                .ant-select-selector {
                    box-shadow: inset 0 0 0 1px #1ca8dd;
                }
            }
        }

        &.ant-select-single {
            .ant-select-selector .ant-select-selection-item {
                line-height: 32px !important;
            }
        }

        &.ant-select-multiple {
            .ant-tag {
                margin: 2px 0;
            }
        }

        &.alternate-bg {
            .ant-input-affix-wrapper {
                background-color: #ffffff;

                &:hover {
                    background-color: #e9e9ea;
                }
            }
        }
        .anticon-caret-down svg{
            .size(16px);
        }

        &.ant-select-status-error {
            background: #f4f6f8 !important;
            border: 1px solid #d96758 !important;

            &.ant-input-affix-wrapper-focused {
                box-shadow: none !important;
            }

            .ant-input {
                &:not(.ant-input-disabled):not(.ant-input-borderless):not(.ant-input-suffix) {
                    box-shadow: none;
                }
            }
        }
    }

    // input-affix-wrapper
    .ant-input-affix-wrapper {
        border: none;
        height: 32px;
        border-radius: 0;
        padding: 4px 10px;
        background-color: #f4f6f8;

        &-lg {
            height: 48px;
        }

        .ant-input-prefix {
            color: #4c4e54;
            margin-right: 8px;
        }

        .anticon svg {
            .size(16px);
        }

        &:hover {
            background-color: #f0f5f8;
        }

        &-focused {
            border: none;
            box-shadow: inset 0 0 0 1px #1ca8dd;
        }

        > .ant-input {
            height: auto;
            background-color: transparent;
        }

        &-status-error {
            background: #f4f6f8 !important;
            border: 1px solid #d96758 !important;

            &.ant-input-affix-wrapper-focused {
                box-shadow: none !important;
            }

            .ant-input {
                &:not(.ant-input-disabled):not(.ant-input-borderless):not(.ant-input-suffix) {
                    box-shadow: none;
                }
            }
        }
    }

    // TextArea
    .ant-input-textarea-has-feedback {
        .ant-input-status-success, .ant-input-status-error {
            padding-right: 30px !important;
        }
        .ant-form-item-feedback-icon {
            font-size: 16px;
        }
    }

    // Dropdown
    .ant-select-dropdown {
        border-radius: 0;
        border: 1px solid #e9e9ea;
        box-shadow: 1px 1px 0px #d2d3d4;

        .ant-select-item {
            .f-text-normal;
            padding: 10px 8px;
            .f-text-medium-content;
            .f-color-dark-black-s80;

            &-option-selected {
                .f-color-blue-s100;
                background-color: transparent;
            }

            &-option-active {
                background-color: #f0f5f8;
            }
        }

        &-empty{
            min-width: fit-content;
        }
    }

    // Dropdown Menu
    .ant-dropdown-menu {
        min-width: 240px;
        border-radius: 2px;
        box-shadow: 0 4px 16px 0 rgba(31,34,41,.16);

        .ant-dropdown-menu-item {
            height: 40px;
            font-weight: 100;
            padding: 8px 16px;
            .f-text-medium-content;
            .f-text-single-line;

            &:hover {
                background-color: #f3fafd;
            }
        }

        &-item-disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    // Filter Dropdown
    .f-filter-dropdown {
        height: 32px;
        display: flex;
        cursor: pointer;
        padding: 4px 8px;
        align-items: center;
        .f-color-dark-black-s60;

        svg {
            display: block;
            .f-color-dark-black-s50;
        }

        &.f-filter-applied {
            border-radius: 4px;
            .f-color-blue-s100;
            background-color: @blue-s5;
            border: 1px solid @blue-s40;

            .anticon-close-circle {
                svg {
                    font-size: 14px;
                    .f-color-blue-s100;
                }
            }
        }
    }
    .ant-dropdown {
        .f-filter-dropdown-menu {
            min-width: 256px;
            max-width: 256px;
            border-radius: 4px;
            background: #fff;
            box-shadow: 0 4px 16px #d2d3d4;

            .f-filter-dropdown-field {
                .ant-picker-input input {
                    .f-text-medium-content;
                    .f-text-single-line;
                }
            }
        }
    }

    // Card
    .ant-card {
        border: none;
        border-radius: 0;

        &-head {
            min-height: 24px;
            padding-right: 0;
            padding-left: 16px;
            border-bottom: none;

            &-wrapper {
                align-items: flex-start;
            }

            &-title {
                font-size: 16px;
                line-height: 24px;
                color: #4c4e54;
                font-weight: 100;
            }
        }

        &-body {
            padding: 0;
        }

        &-extra {
            padding: 0;
        }

        &.Domains {
            .ant-card-body {
                padding: 16px;

                > a {
                    display: flex;
                    margin-bottom: 8px;
                }
            }

            .stats-tile-card {
                height: auto;

                .tile-logo {
                    margin-right: 8px;
                }

                .tile-content {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;

                    .tile-count {
                        margin-left: 8px;

                        &::before {
                            content: '(';
                        }

                        &::after {
                            content: ')';
                        }
                    }

                    h5 {
                        margin: 0 !important;
                    }
                }
            }
        }
    }

    // Buttom
    .ant-btn {
        .f-text-normal;
        .f-text-medium-content;
        .f-text-single-line;
        border-radius: 0;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-left: 4px;
        padding-right: 4px;
        box-shadow: none;
        align-items: center;
        display: inline-flex;

        &::after {
            display: none;
        }

        .anticon svg,
        svg {
            .size(16px);
            vertical-align: top;

            + span {
                margin-left: 8px;
            }
        }

        &.icon-button {
            .size(24px);
            border-radius: 0;
            padding: 4px !important;
        }

        &-primary,
        &-dangerous,
        &-default {
            border: none;
            padding: 8px 16px;
            border-radius: 2px;
        }

        &-primary {
            .f-color-white;
            background-color: #1ca8dd;

            &:focus {
                background-color: #33b1e0;
            }

            &:hover {
                background-color: #49b9e4;
            }

            &[disabled],
            &[disabled]:hover {
                background-color: #1ca8dd;
            }

            &:focus,
            &:focus-visible {
                outline: 2px solid #5f767c !important;
            }
        }

        &-default {
            .f-color-dark-black-s80;
            background-color: #e9e9ea;

            &:focus {
                background-color: #e9e9ea;
            }

            &:hover {
                .f-color-dark-black-s100;
                background-color: #e9e9ea;
            }

            &[disabled] {
                background-color: #e9e9ea;
            }
        }

        &-dangerous {
            .f-color-white;
            background-color: #d96758;

            &:focus {
                background-color: #b23827;
            }

            &:hover {
                .f-color-white;
                background-color: #d75645;
            }

            &[disabled] {
                background-color: #d96758;
            }

            .anticon {
                color: #d96758
            }
        }

        &-dashed {
            border: none;
            color: #5f767c;
            background-color: transparent;

            &:hover {
                color: #5f767c;
                background-color: #edf0f0;
                box-shadow: inset 0px -3px 0px #5f767c;
            }
        }

        &-link {
            height: auto;
            color: #1ca8dd;
            padding: 0 !important;

            span:not(.anticon) {
                text-align: left;
                white-space: normal;
                word-break: break-word;
            }
        }

        &-text {
            padding: 8px;
            background-color: transparent;
            .f-color-dark-black-s80;

            &:hover,
            &:focus {
                background-color: transparent;
                outline: 2px solid #49B9E4;
            }

            &.btn-selected {
                color: #1ca8dd !important;
            }
        }

        .ant-btn-loading-icon {
            display: flex;
        }

        &[disabled] {
            opacity: 0.3;
        }

        &.f-btn-underline {
            border-radius: 0;
            padding: 0 4px;
            color: #5f767c !important;
            background-color: transparent;

            &:hover {
                background-color: #EDF0F0;
                box-shadow: inset 0 -1px 0 0 currentColor;
            }

            &:active,
            &.f-btn-active {
                box-shadow: inset 0 -1px 0 0 currentColor;
            }

            &:focus-visible {
                background-color: #EDF0F0;
            }

            &.v-btn--disabled {
                background-color: transparent !important;
                color: #5f767c !important;
            }
        }

        &:focus,
        &:focus-visible {
            outline: 2px solid #49B9E4;
        }
    }

    // Divider
    .ant-divider {
        border-top-color: #edf0f0;
    }

    // List
    .ant-list {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        background-color: #ffffff;

        &-item {
            padding: 16px;

            &:not(:last-child) {
                border-bottom: 1px solid #edf0f0;
            }
        }
    }

    // Menu
    .ant-menu {
        .f-text-medium-content;
        .f-text-single-line;

        > .ant-menu-item {
            height: 73px;
            margin: 0 !important;
            .f-text-medium-content;
            .f-text-single-line;
            .f-color-dark-black-s80;
            padding: 8px 16px !important;

            &.ant-menu-item-selected,
            &.ant-menu-item-active {
                background-color: #f0f5f8;
            }

            &.ant-menu-item-selected {
                box-shadow: -3px 0px 0px #1ca8dd;

                &::after {
                    display: none;
                }
            }
        }
    }

    // Breadcrumb
    .ant-breadcrumb {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        .f-text-medium-content;

        a {
            white-space: normal;
            word-break: break-word;
            .f-color-dark-black-s50;

            &:hover {
                text-decoration: underline;
            }
        }

        > span:last-child {
            a {
                pointer-events: none;
                .f-color-dark-black-s80;
            }
        }

        &-separator {
            .f-color-dark-black-s50;
        }
    }

    // Tag
    .ant-tag {
        margin: 4px;
        border: none;
        max-width: 100%;
        .f-text-content;
        padding: 4px 8px;
        border-radius: 12px;
        .f-color-dark-black-s80;
        background-color: #e9e9ea;
        .flex(inline-flex, @align: center);

        .anticon-close {
            .f-color-dark-black-s50;
        }

        .ant-avatar {
            margin-right: 8px;
            margin-left: -4px;
        }

        &.label-tag {
            border-radius: 2px;
        }

        &.pri-tag {
            .f-color-white;
            background-color: #1ca8dd;

            .anticon-close {
                .f-color-white;
            }
        }

        &.green-tag {
            .f-color-white;
            background-color: #86b62c;

            .anticon-close {
                .f-color-white;
            }
        }

        &.grey-tag {
            .f-color-white;
            border-radius: 2px;
            background-color: #5f767c;

            .anticon-close {
                .f-color-white;
            }

            a {
                .f-color-white;
            }
        }

        &.grey-light-tag {
            .f-color-gray-s100;
            background-color: @dark-black-s5;

            .anticon-close {
                .f-color-gray-s100;
            }

            a {
                .f-color-gray-s100;
            }
        }

        &.base-tag {
            .f-color-blue-s100;
            background-color: #F3FAFD;

            .anticon-close {
                .f-color-blue-s100;
            }

            a {
                .f-color-blue-s100;
            }
        }
    }

    // Checkbox
    .ant-checkbox-wrapper {
        display: flex;
        align-items: center;

        .ant-checkbox {
            top: auto;

            &-inner {
                border-radius: 2px;
                border: 1px solid #1ca8dd;
            }

            &-checked {
                .ant-checkbox-inner {
                    background-color: #1ca8dd;
                }
            }

            & + span {
                .f-text-medium-content;
                .f-text-single-line;
                display: flex;
                align-items: center;
                .f-color-dark-black-s80;
            }

            input {
                &:focus {
                    + span {
                        outline: 2px solid #49B9E4;
                    }
                }
            }
        }
    }

    // Table
    .ant-table {
        box-shadow: none;

        .ant-table-container {
            @media (max-width: @screen-lg) {
                overflow-x: auto;
            }
        }

        &-thead {
            .ant-table-cell {
                &.ant-table-column-has-sorters {
                    &:focus-visible {
                        .ant-table-column-sorters {
                            outline: 2px solid #49B9E4;
                        }
                    }
                }

                .f-text-normal;
                border-radius: 0;
                .f-text-medium-content;
                .f-text-single-line;
                .f-color-dark-black-s50;
                background-color: transparent;

                &::before {
                    display: none;
                }
            }

            > tr > th {
                padding: 11px 8px;

                @media (max-width: @screen-lg) {
                    white-space: nowrap;
                }

                &:first-child {
                    padding-left: 16px;
                }
            }
        }

        &-tbody {
            > tr > td {
                .f-text-medium-content;
                border-bottom: none;
                .f-color-dark-black-s80;
                padding: 16px 8px;

                &:first-child {
                    padding-left: 16px;
                }

                @media (max-width: @screen-md) {
                    white-space: nowrap;
                }
            }
        }

        &.ant-table-small {
            .ant-table-thead > tr > th {
                padding: 8px;
                .f-text-medium-content;
                .f-text-single-line;

                &:first-child {
                    padding-left: 24px;
                }
            }

            .ant-table-tbody > tr > td {
                .f-text-medium-content;

                &:first-child {
                    padding-left: 16px;
                }
            }
        }
    }

    // Tabs
    .ant-tabs {
        .ant-tabs-nav-list {
            flex-grow: 1;

            @media (max-width: @screen-md) {
                gap: 4px;
                flex-direction: column;
            }

            .ant-tabs-ink-bar {
                top: 0;
                height: 3px;
                bottom: auto;
                background-color: #86b62c;
            }
        }

        > .ant-tabs-nav {
            .ant-tabs-tab {
                flex-grow: 1;
                margin-left: 4px;
                margin-right: 4px;
                padding: 0;
                .f-text-normal;
                .f-text-medium-content;
                .f-color-dark-black-s80;
                justify-content: center;

                &-disabled {
                    opacity: 0.3;
                }

                &.ant-tabs-tab-active > div {
                    .f-color-securiti-green;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:nth-last-child(2) {
                    margin-right: 0;
                }

                .ant-tabs-tab-btn {
                    flex-grow: 1;
                    padding: 12px 16px;
                    text-align: center;
                    background-color: #ffffff;

                    &:focus {
                        // outline: 2px solid #49B9E4;
                        box-shadow: inset 0 0 0 2px #49B9E4;
                    }
                }

                @media (max-width: @screen-md) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            > .ant-tabs-nav-operations {
                display: none;
            }
        }

        &-top > .ant-tabs-nav {
            margin-bottom: 8px;
        }
    }

    // Switch
    .ant-switch {
        min-width: 32px;
        .size(32px, 16px);
        border-radius: 15px;
        background-color: #f4f6f8;

        &-handle {
            top: 4px;
            left: 4px;
            .size(8px);

            &:before {
                box-shadow: none;
                background-color: #1ca8dd;
            }
        }

        &-checked {
            background-color: #1ca8dd;

            .ant-switch-handle {
                left: 20px;

                &:before {
                    background-color: #f4f6f8;
                }
            }
        }

        &:focus {
            box-shadow: none;
            outline-offset: 2px;
            outline: 2px solid #49B9E4;
        }
    }

    // Empty
    .ant-empty {
        &-description {
            .f-text-medium-content;
            .f-color-dark-black-s50;
        }
    }

    // Modal
    .ant-modal {
        &-content {
            border-radius: 0;

            .ant-modal-close {
                &:focus,
                &:hover {
                    outline: 2px solid #49B9E4 !important;
                }
            }
        }

        &-header {
            padding: 24px 24px 8px;
            border-bottom: 1px solid #f2f3f2;
            .flex(flex, @direction: column, @align: center);
        }

        &-title {
            .f-text-normal;
            .f-text-big-content;
            .f-color-dark-black-s80;
            .f-word-break-break-word;
        }

        &-body {
            padding: 16px 24px;
        }

        &-footer {
            padding: 16px 8px 24px;
            .flex(flex, @justify: center);

            &:empty {
                display: none;
            }
        }

        &-confirm {
            .ant-modal-body {
                padding: 0;
            }

            &-body {
                > .anticon {
                    display: none;
                }
            }

            &-title {
                .f-text-normal;
                text-align: center;
                .f-text-big-content;
                padding: 24px 8px 8px;
                .f-color-dark-black-s80;
                border-bottom: 1px solid #f2f3f2;
            }

            &-content {
                .f-text-normal;
                .f-text-content;
                padding: 16px 24px;
                text-align: center;
                margin: 0 !important;
                .f-color-dark-black-s80;
            }

            .ant-modal-confirm-btns {
                float: none;
                margin-top: 0;
                padding: 16px 8px 24px;
                .flex(flex, @justify: center);
                border-top: 1px solid #f2f3f2;
                flex-direction: row-reverse;

                .ant-btn {
                    margin-left: 8px;
                }
                .ant-btn + .ant-btn {
                    margin-left: 0;
                }
            }
        }
    }

    // Form
    .ant-form {
        &-item {
            margin-bottom: 16px;

            &-label {
                & > label {
                    .f-text-medium-content;
                    .f-text-single-line;
                    .f-color-dark-black-s80;
                }
            }

            &-control-input-content .ant-typography {
                margin-bottom: 4px;
                .f-color-dark-black-s50;
            }

            &-children-icon {
                display: none;
            }

            &-has-error {
                :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
                    background-color: #f4f6f8;
                    box-shadow: inset 0 0 0 1px #d96758;

                    &:hover {
                        background-color: #f4f6f8;
                    }

                    &:focus {
                        box-shadow: inset 0 0 0 1px #d96758;
                    }
                }
            }
        }
    }

    // Steps
    .ant-steps {
        &-item {
            &-icon {
                .size(24px);
                .f-text-semibold;
                .f-text-big-content;

                .ant-steps-icon {
                    top: -1px;
                }
            }

            &-title {
                .f-text-big-content;
                .f-color-dark-black-s80;

                &::after {
                    top: 13px;
                    background-color: #8f9094;
                }
            }

            &-process {
                > .ant-steps-item-container {
                    > .ant-steps-item-icon {
                        border-color: #86b62c;
                        background-color: #86b62c;
                    }

                    > .ant-steps-item-content {
                        > .ant-steps-item-title {
                            &::after {
                                background-color: #8f9094;
                            }
                        }
                    }
                }
            }

            &-wait {
                > .ant-steps-item-container {
                    > .ant-steps-item-icon {
                        .f-color-dark-black-s50;
                        border-color: #e9e9ea;
                        background-color: #e9e9ea;
                    }

                    > .ant-steps-item-content {
                        > .ant-steps-item-title {
                            .f-color-dark-black-s50;

                            &::after {
                                background-color: #8f9094;
                            }
                        }
                    }
                }
            }

            &-finish {
                > .ant-steps-item-container {
                    > .ant-steps-item-icon {
                        border-color: #86b62c;
                        background-color: #86b62c;

                        .ant-steps-icon {
                            .f-color-white;
                        }
                    }

                    > .ant-steps-item-content {
                        > .ant-steps-item-title {
                            &::after {
                                background-color: #86b62c;
                            }
                        }
                    }
                }
            }
        }
    }

    // Ant row
    .ant-dropdown-trigger {
        .f-text-medium-content;
        .f-text-single-line;
    }

    // High Contrast Ratio
    &.f-high-contrast {
        .f-color-high-contrast {
            color: @dark-black-s100 !important;
            text-decoration-color: @dark-black-s100 !important;

            > span {
                color: @dark-black-s100 !important;

                &.f-color-dark-black-s80 {
                    color: @dark-black-s80 !important;
                }
            }
        }

        .f-color-mid-contrast {
            color: @dark-black-s80 !important;

            > span {
                color: @dark-black-s80 !important;

                &.f-color-dark-black-s80 {
                    color: @dark-black-s80 !important;
                }
            }
        }

        .f-color-risk-very-high {
            color: @red-s60 !important;

            svg {
                color: currentColor;
            }
        }

        .f-error-log-card {
            background-color: #FBEEEC;

            span {
                color: @dark-black-s100 !important;
            }

            p {
                color: @red-s60 !important;
            }
        }

        .f-certified {
            color: @green-s60;
            border-color: @green-s60;

            > div {
                color: @green-s60;
            }
        }

        .f-under-review {
            .f-color-dark-black-s100;
            border-color: @dark-black-s50;
            background-color: @dark-black-s5;

            > div {
                .f-color-dark-black-s100;
            }
        }

        .f-default-status {
            .f-color-dark-black-s80;
            border-color: @dark-black-s50;
            background-color: @dark-black-s5;

            > div {
                .f-color-dark-black-s80;
            }
        }

        a {
            color: @dark-black-s100;

            &:focus,
            &:active {
                outline: 2px solid @dark-black-s100;
            }
        }

        // Badge
        .ant-badge {
            &-count {
                .f-color-dark-black-s80;
                border-color: @dark-black-s50 !important;
                background-color: @dark-black-s5 !important;
            }
        }
        // Buttom
        .ant-btn {
            &-primary {
                background-color: @dark-black-s100;

                &:focus {
                    background-color: @dark-black-s80;
                }

                &:hover {
                    background-color: @dark-black-s80;
                }

                &:active {
                    border: 2px solid @dark-black-s30;
                }

                &[disabled],
                &[disabled]:hover {
                    background-color: @dark-black-s30;
                }

                &:focus,
                &:focus-visible {
                    outline: 2px solid @dark-black-s100 !important;
                }
            }

            &-dangerous {
                .f-color-white;
                background-color: @red-s60 !important;

                > span, svg {
                    .f-color-white;
                }
            }

            &.f-btn-underline {
                background-color: transparent;
                color: @dark-black-s80 !important;
            }

            &-link,
            &.btn-selected {
                .f-color-dark-black-s100;
            }

            &:focus,
            &:focus-visible {
                outline: 2px solid @dark-black-s100;
            }

            &-dashed {
                &:focus {
                    color : @dark-black-s100;
                }
            }

            &-text {
                .f-color-dark-black-s100;
            }
        }

        // Breadcrumb
        .ant-breadcrumb {
            a {
                color: @dark-black-s80 !important;
            }
        }

        // Tags
        .ant-tag {
            &.pri-tag,
            &.base-tag,
            &.ant-tag-processing {
                .f-color-white;
                background-color: @dark-black-s100;
            }

            &-success {
                color: @green-s60 !important;
                border-color: #D6E9CA !important;
                background-color: #F7FBF4 !important;
            }

            &-error {
                color: @red-s60 !important;
                border-color: @red-s60 !important;
                background-color: #FBEEEC !important;
            }

            &.pri-tag-outline {
                .f-color-dark-black-s100;
                border-color: @dark-black-s50 !important;
                background-color: @dark-black-s5 !important;
            }

            &.green {
                background-color: @green-s60;
            }

            &.red {
                background-color: @red-s60;
            }
        }

        .f-pri-tag-outline {
            .f-color-dark-black-s100;
            border-color: @dark-black-s50 !important;
            background-color: @dark-black-s5 !important;
        }

        // Tabs
        .ant-tabs {
            .ant-tabs-nav-list {
                .ant-tabs-ink-bar {
                    background-color: @green-s60;
                }
            }

            > .ant-tabs-nav {
                .ant-tabs-tab {

                    &.ant-tabs-tab-active > div {
                        color: @green-s60 !important;
                    }

                    .ant-tabs-tab-btn {
                        &:focus {
                            outline: 2px solid @green-s60;
                        }
                    }
                }
            }
        }

        // Filter Dropdown
        .f-filter-dropdown {
            .f-color-dark-black-s80;

            svg {
                .f-color-dark-black-s80;
            }

            &.f-filter-applied {
                .f-color-dark-black-s100;
                border-color: @dark-black-s50;
                background-color: @dark-black-s5;
            }

            .anticon-close-circle {
                svg {
                    .f-color-dark-black-s100;
                }
            }
        }

        .ant-typography {
            &:not([class*='f-color-']) {
                &.ant-typography-secondary {
                    .f-color-dark-black-s80;
                }
            }

            .ant-typography-edit {
                &:focus,
                &:focus-visible {
                    outline-color: @dark-black-s100
                }
            }
        }


        // Stats Tile
        .platform-wrapper,
        .stats-tile-card-wrapper {
            .tile-title {
                .f-color-dark-black-s80;
            }
        }

        // Table
        .ant-table {
            &-thead {
                .ant-table-cell {
                    &.ant-table-column-has-sorters {
                        &:focus-visible {
                            .ant-table-column-sorters {
                                outline-color: @dark-black-s100
                            }
                        }
                    }

                    .f-color-dark-black-s80;
                }
            }
        }

        // Empty
        .ant-empty {
            &-description {
                .f-color-dark-black-s80;
            }
        }

        .ant-menu {
            .ant-menu-item-group-title {
                .f-color-dark-black-s80;
            }
        }

         // Form
        .ant-form {
            &-item {
                &-control-input-content .ant-typography {
                    .f-color-dark-black-s80;
                }

                &-explain-error {
                    color: @red-s60 !important;
                }
            }
        }

        // Steps
        .ant-steps {
            &-item {
                &-title {
                    .f-color-dark-black-s80;
                }

                &-wait {
                    > .ant-steps-item-container {
                        > .ant-steps-item-icon {
                            .f-color-dark-black-s80;
                        }

                        > .ant-steps-item-content {
                            > .ant-steps-item-title {
                                .f-color-dark-black-s80;
                            }
                        }
                    }
                }
            }
        }

        .ant-form-item-explain {
            &.ant-form-item-explain-connected {
                .f-color-dark-black-s80;
            }
        }

        // Select
        .ant-select {
            &.ant-select-multiple {
                .ant-select-selector {
                    .ant-select-selection-placeholder {
                        .f-color-dark-black-s100;
                    }
                }
            }

            &.ant-select-single {
                .ant-select-selector .ant-select-selection-item {
                    .f-color-dark-black-s100;
                }
            }
        }

        // Dropdown
        .ant-select-dropdown {
            .ant-select-item {
                &-option-selected {
                    .f-color-dark-black-s100;
                    background-color: transparent;
                }
            }
        }

        // Picker (Date, Time, etc.)
        .ant-picker-dropdown  {
            .ant-picker-panel-container {
                .ant-picker-cell {
                    .ant-picker-cell-inner {
                        .f-color-dark-black-s80;
                    }

                    &.ant-picker-cell-in-view {
                        .ant-picker-cell-inner {
                            .f-color-dark-black-s100;
                        }
                    }

                    &.ant-picker-cell-selected {
                        .ant-picker-cell-inner {
                            .f-color-white;
                            background-color: @dark-black-s100;

                            &::before {
                                border-color: @dark-black-s100;
                            }
                        }
                    }
                }
            }
        }

         // Switch
        .ant-switch {
            &-handle {
                &:before {
                    background-color: @dark-black-s100;
                }
            }

            &-checked {
                background-color: @dark-black-s100;

                .ant-switch-handle {
                    &:before {
                        background-color: #f4f6f8;
                    }
                }
            }

            &:focus {
                outline-color: @dark-black-s100;
            }
        }

        // Checkbox
        .ant-checkbox-wrapper {
            .ant-checkbox {
                &-inner {
                    border-color: @dark-black-s80 !important;
                }

                &-checked {
                    .ant-checkbox-inner {
                        background-color: @dark-black-s100 !important;
                    }

                    &::after {
                        border-color: @dark-black-s100 !important;
                    }
                }

                & + span {
                    .f-color-dark-black-s100;
                }

                input {
                    &:focus {
                        + span {
                            outline-color: @dark-black-s100;
                        }
                    }
                }
            }
        }

         // input-affix-wrapper
        .ant-input-affix-wrapper {
            .ant-input-prefix {
                color: @dark-black-s100;
            }

            .ant-input-suffix {
                .ant-form-item-feedback-icon-error {
                    color: @red-s60;
                }
            }

            &-status-error {
                border-color: @red-s60 !important;
            }
        }
    }
}

// Drawer
.ant-drawer {
    &.ant-drawer-right {
        &.ant-drawer-open {
            .ant-drawer-content {
                height: calc(100% - 48px);

                .ant-drawer-content-wrapper {
                    box-shadow: none;

                    @media (max-width: @screen-md) {
                        width: 90% !important;
                    }
                }
            }
        }
    }

    .ant-drawer-body {
        padding: 0;
    }

    .ant-drawer-footer {
        padding: 24px;
    }
}

.ant-checkbox {
    &:not(.ant-checkbox-disabled) {
        &.ant-checkbox-checked {
            .ant-checkbox-inner {
                border-color: @primary-color;
            }
        }
        &:not(.ant-checkbox-checked) {
            .ant-checkbox-inner {
                border-color: @gray-7;
            }
        }
    }
}


// Collapse
.ant-collapse {
    &-header {
        &:focus {
            outline: 2px solid #49B9E4 !important;
        }
    }
}


// Style override for accessibility high contrast mode
.f-high-contrast {
    // Override the color of the secondary text from s50 to s80
    [class*="f-color-dark-black-s50"] {
        color: @dark-black-s80 !important;
    }
    // Override the color of the secondary text from s60 to s80
    [class*="f-color-dark-black-s60"] {
        color: @dark-black-s80 !important;
    }
    // Override the color of the primary text from s80 to s100
    [class*="f-color-dark-black-s80"] {
        color: @dark-black-s100 !important;
    }
    // Override the color of the gray text from gray-s100 to black s100
    [class*="f-color-gray-s100"] {
        color: @dark-black-s100 !important;
    }
    // Override the color of the securiti blue text to black s100
    div[class*="f-color-blue-s100"],
    span[class*="f-color-blue-s100"] {
        color: @dark-black-s100 !important;
    }
}